.login-space{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form {
    width: 300px;
    outline: none;
}

.login-form-button {
    text-align: center;
    width: 100%;
    color: #fdfdfd;
}

.login-form-input {
    border: none;
    background: rgba(255, 0, 0, alpha);
    color: #fdfdfd;
    outline: none;
}