.content-car-registration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px auto;
    width: 700px;
    .label-car-registration {
        font-size: 22pt;
    }
}
