.content-card {
  margin: 24px 16px;
  height: calc(100vh - 282px)
}

.content-open-modal{
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
}

ul {
  padding-left: 0px;
}