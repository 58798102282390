.form-order-registration{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 700px;
}

.content-form{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 510px;
}