.ant-descriptions-item-label::after{
    content: " ";
}

.ant-descriptions-item-container .ant-descriptions-item-label, .ant-descriptions-item-container .ant-descriptions-item-content{
    font-size: 14pt;
}

.ant-descriptions-item-label{
    font-weight: bold;
}

.content-service-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 24px;
    white-space: pre;
}

.content-title {
    display: flex;
    flex-direction: row;
}

.ant-descriptions-row > th, .ant-descriptions-row > td{
    padding-bottom: 0px;
}

.ant-divider-inner-text{
    font-size: 12pt;
}

.ant-descriptions-item-content{
    margin-bottom: 32px;
}

.title{
    font-weight: bold;
    font-size: 24px;
    margin: auto;
}