.ant-form-item .ant-mentions, .ant-form-item textarea.ant-input{
    height: 200px;
    width: 500px;
      margin-right: 5px;
      margin-right: 5px;
      margin-bottom: 10px;
      margin-top: 10px;
      border-radius: 7px;
    font-size: 14pt;
}
  
.ant-form-item .ant-mentions, .ant-form-item textarea.ant-input:focus {
    border-color: #d9d9d9 !important;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
}
  
.ant-form-item .ant-mentions, .ant-form-item textarea.ant-input:hover {
    border-color: #d9d9d9 !important;
}
