@mixin input {
    height: 50px;
    width: 250px;
      margin-right: 5px;
      margin-right: 5px;
      margin-bottom: 10px;
      margin-top: 10px;
      border-radius: 7px;
    font-size: 14pt;
}

@mixin inputFocus {
     border-color: #d9d9d9 !important;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
}

@mixin inputHover {
    border-color: #d9d9d9 !important;
}

.input{
    @include input();
}
  
.input:focus  {
    @include inputFocus();
}
  
.input:hover {
    @include inputHover();
}  

.ant-input-number{
    @include input();
    padding-top: 8px;
}

.ant-input-number:focus, .ant-input-number-focused{
    @include inputFocus();
}

.ant-input-number:hover{
    @include inputHover();
}