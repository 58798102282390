@mixin Home-space($marginLeft) {
    margin-top: 64px;
    margin-left: $marginLeft;
    margin-right: 16px;
    transition: all 0.2s;
  }
  
  .content-home-space {
    @include Home-space(200px);
  
    &-collapsed {
      @include Home-space(80px);
    }
  }