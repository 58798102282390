$-width: var(--width);

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  height: 50px;
  width: $-width;
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 7px;
  font-size: 14pt;
  padding-top: 8px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search{
  padding-top: 8px;
}