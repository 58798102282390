.menu{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0; 
}

@mixin logo($size) {
    width: $size;
    height: $size;
    transition: all 0.2s;
}

.logo-menu {
    @include logo(200px);
    &-collapsed{
      @include logo(80px);
    }
}