@mixin fontSize {
    font-size: 14pt;
}

.DatePicker{
    height: 50px;
    width: 250px;
    margin-right: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 7px;
    @include fontSize();
}

.ant-picker-input > input:placeholder-shown{
    @include fontSize();
}

.ant-picker-input > input{
    @include fontSize();
}