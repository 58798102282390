.home-card{
    margin-bottom: 10px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s linear;
}

.home-card:hover {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}