@mixin hearder($marginLeft) {
    background: #fff;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    margin-left: $marginLeft;
    display: flex;
    display: inline;
    transition: all 0.2s;
}

.header-home {
    @include hearder(200px);
    &-collapsed {
        @include hearder(80px);
    }
}

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.avatar-header{
    position: fixed;
    right: 34px;
    top: 16px;
    background-color: #1890ff;
    border-radius: 50%;
    cursor: pointer;
    &:hover{
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
    }
}